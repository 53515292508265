<!--
 * @Author: xr
 * @Date: 2022-02-16 11:43:07
 * @LastEditors: xr
 * @LastEditTime: 2022-03-04 11:19:47
 * @FilePath: \postStation-office\src\views\professional.vue
-->
<template>
   <div class="main">
    <div class="main_box">
      <a-row type="flex" :gutter="20" justify="space-between" style="padding:0px 10px 10px;">
        <a-col :span="7" style="padding-left: 0px">
          <find-demand class="find-item" title="找项目" :technicalProjectContent="technicalProjectContent" />
          <find-demand class="find-demand" title="找需求" :technicalProjectContent="technicalProjectContent"/>
        </a-col>
        <a-col :span="17">
          <a-row style="min-height:68vh" class="sub-component-background-color">
            <search-box ref="searchModal" searchLable="主要研究领域" @search="search" />
            <a-empty v-if="professionalData.length===0" class="empty-data"/>
            <a-row v-else type="flex" >
              <a-col @click="goDetail(item.id)" v-for="(item,index) in professionalData" :key="index" style="text-align: center;">
                <li class="avator" :style="item.headImg?'':'background-image:url('+require('../assets/img/'+item.sex+'.jpg')+')'">
                  <img :src="item.headImg?item.headImg:''" v-if="item.headImg !== ''" class="expert-avatar">
                </li>
                <span class="name">{{item.name.slice(0,5)}}</span>
              </a-col>
            </a-row>
          </a-row>
          <a-row type="flex" justify="center">
            <a-pagination v-show="professionalData.length" v-model="pageNo" :total="total" :default-page-size="pageSize" @change="onChange" style=" margin:20px 40px 10px 0px"/>
          </a-row>
        </a-col>
      </a-row>
      <!-- 友情链接 -->
      <link-box />
    </div>
   </div>
</template>

<script>
import searchBox from '@comp/search'
import linkBox from '@comp/layout/link'
import FindDemand from '@comp/findDemand'
import { getProfessional, getDict } from '@/api/index'
import { Page } from '@/mixins/page'
import { Station } from '@/mixins/station'

export default {
  name: 'Professional',
  mixins: [Page, Station],
  components: {
    searchBox,
    linkBox,
    FindDemand
  },
  data() {
    return {
      // 专家列表
      professionalData: [],
      technicalProjectContent: [],
      pageSize: 30
    }
  },
  methods: {
    /**
     * @description:跳转详情
     * @param {*} id
     */
    goDetail(id) {
      this.$router.push({ name: 'professionalDetail', query: { id: id }})
    },
    /**
     * @description:获取列表
     */
    getList() {
      // 判断是否为子站
      this.getStationId()
      const params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        mainResearchAreas: this.searchData.mainResearchAreas,
        name: this.searchData.name,
        stationId: this.stationId
      }
      // 获取专家列表
      getProfessional(params).then(res => {
        this.professionalData = res.result.records
        this.total = res.result.total * 1
      })
    }
  },
  activated() {
    // 获取左侧组件字典值
    getDict('application_field').then(res => {
      this.technicalProjectContent = res.result
    })
    // 请求参数赋值
    this.stationId = this.$route.query.stationId
    this.searchData = this.$route.query
    this.getList()
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // 无参数清除请求参数
      if (JSON.stringify(to.params) === '{}' && JSON.stringify(to.query) === '{}') {
        vm.$refs.searchModal.form.name = ''
        vm.$refs.searchModal.form.mainResearchAreas = ''
      } else {
        // 刷新后搜索组件赋值
        vm.$refs.searchModal.form.name = to.query.name
        vm.$refs.searchModal.form.mainResearchAreas = to.query.mainResearchAreas
      }
      // 清空剩余数据
      vm.data = []
      vm.total = 0
    })
  }
}
</script>

<style scoped>
.table-header{
  color: #000;
  font-size: 16px;
  font-weight: bold;
}
.avator{
  width: 70px;
  height: 70px;
  border: 1px solid #d2d2d2;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 10px;
  margin-left: 39px;
  cursor: pointer;
  display:flex;
  align-items: center; /*垂直居中*/
  justify-content: center; /*水平居中*/

}
.name{
  font-size: 14px;
  position: relative;
  left: 18px;
  cursor: pointer;
}

.expert-avatar{
  width: 100%;
}
@import "../assets/css/newsInformation.css";
</style>
